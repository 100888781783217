<template>
  <transition name="modal" v-if="showModal">
    <div class="modal-mask" @click="toggleModal()">
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop="doNothing()">

          <div class="modal-header">
            <div class="row">
              <div class="modal-header-text col text-start">
                Rezervare nouā
              </div>
              <div @click="toggleModal()" class="col text-end close-button">
                <img :src="require('../assets/svg/close.svg')" width="25" height="25"/>
              </div>
            </div>
          </div>

          <div class="modal-body">
            <div class="row shadow-box m-4 p-2">
              <div class="d-none d-lg-block col-2 my-auto">
                <object data="img/svg/hourglass.svg" width="50"
                        type="image/svg+xml"></object>
              </div>
              <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 text-start">
                <div id="booking_text">
                  <h5>Rezervarea ta durează:</h5>
                  <h5 class="text-yellow m-0">{{duration}}</h5>
                  <h5 v-html="durationExtended" class="m-0"></h5>
                </div>
              </div>
            </div>
            <div class="p-2">
              <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                  <input v-model="name" type="text" placeholder="Nume" name="name"
                         id="first-name" required="required" class="form_input"/>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                  <input v-model="email" type="text" placeholder="Email" name="email"
                         id="email" required="required" class="form_input"/>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                  <input v-model="phone" type="text" placeholder="Telefon" name="phone"
                         oninput="this.value =
                       this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                         pattern="[0-9.]+" id="phone" required="required" class="form_input"/>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                  <input type="number" v-model="pers" name="pers"
                         min="4" max="12" class="form_input" placeholder="Persoane">
                </div>
                <div class="col-12">
                  <textarea v-model="message" rows="1" placeholder="Observții" name="message"
                            id="text_message" required="required" class="form_input"
                            style="min-height: 70px"></textarea>
                </div>
                <div v-show="error" class="col-12 text-start"
                     style="color: #dc3b2f;">
                  <b>{{error}}</b>
                </div>
              </div>
              <div class="row text-start">
                <label>
                  <input type="checkbox" v-model="gdpr">
                  Sunt de acord sā fiu contactat telefonic /
                  prin email cu privire la detaliile de
                  confirmare a rezervārii.
                </label>
                <label>
                  <input type="checkbox" v-model="covid">
                  Am citit și sunt de acord cu
                  <router-link :to="{ name: 'covid'}"
                               target="_blank">
                    <strong>
                      regulamentul COVID-19
                    </strong>
                  </router-link>
                  și cu
                  <router-link :to="{ name: 'regulament'}"
                               target="_blank">
                    <strong>
                      regulamentul de ordine interioarā
                    </strong>
                  </router-link>
                </label>
                <small class="mt-3">
                  Disclaimer: Datele personale nu sunt stocate sau asociate în
                  vreun fel titularilor
                  rezervarii. Acest site nu foloseste in niciun scop detalii
                  de identificare personalā
                  a utilizatorilor.
                </small>
              </div>
              <div class="row mt-2">
                <div class="col-6 text-start">
                  <input type="submit" v-on:click="bookNow($event)"
                         value="Trimite" name="submit" id="send_message"
                         class="form_submit px-4 py-1"/>
                </div>
                <div class="col-6 text-end">
                  <div v-if="pers && durationMinutes"
                       class="col px-2 price">
                    Preț estimativ: <strong>{{price}} RON</strong>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment';
import VueSweetalert2 from 'vue-sweetalert2';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.min.css';

window.app.use(VueSweetalert2);

export default {
  name: 'RezervariModal',
  props: {
    showModalProp: {
      type: Boolean,
      default: false,
    },
    durationExtended: {
      type: String,
      default: '',
    },
    duration: {
      type: String,
      default: '',
    },
    durationMinutes: {
      type: Number,
      default: 0,
    },
    startDate: {
      type: Date,
      default: null,
    },
    endDate: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      showModal: this.showModalProp,
      name: '',
      phone: '',
      email: '',
      pers: 4,
      gdpr: false,
      covid: false,
      message: '',
      error: '',
      price: '',
    };
  },
  watch: {
    showModalProp() {
      this.showModal = this.showModalProp;
    },
    showModal() {
      document.querySelector('body').style.overflow = this.showModal ? 'hidden' : '';
    },
    name() {
      this.hideError();
    },
    phone() {
      this.hideError();
    },
    email() {
      this.hideError();
    },
    message() {
      this.hideError();
    },
    pers() {
      this.hideError();
      if (this.pers > 12) this.pers = 12;
      if (this.pers < 4) this.pers = 4;
      this.calculatePrice();
    },

    durationMinutes() {
      this.calculatePrice();
    },
  },
  methods: {
    doNothing() {
    },
    toggleModal() {
      this.showModal = !this.showModal;
      this.$emit('modalToggled', this.showModal);
    },
    async bookNow() {
      if (this.duration === '') {
        this.$swal('Opps!', 'Te rugam să alegi o dată din calendar!', 'error');
        return false;
      }

      if (this.name.length === 0 || this.email.length === 0
          || this.phone.length === 0 || this.pers.length === 0 || this.gdpr === false
          || this.covid === false) {
        this.error = 'Nu ai completat toate câmpurile!';
        return false;
      }

      if (!this.validEmail()) {
        this.error = 'Email-ul este invalid!';
        return false;
      }

      // const $this = this;
      const response = await fetch(`${window.apiPath}calendar.php?name=${this.name
      }&email=${this.email
      }&phone=${this.phone
      }&pers=${this.pers
      }&message=${this.message
      }&startDate=${moment(this.startDate).toISOString()
      }&endDate=${moment(this.endDate).toISOString()
      }&pauseEndDate=${moment(this.endDate).add(30, 'm').toISOString()
      }&beforeStartDate=${moment(this.startDate).subtract(30, 'm').toISOString()
      }&price=${this.price
      }&duration=${this.duration
      }&durationExtended=${this.durationExtended
      }&booking_text=${encodeURIComponent(document.querySelector('#booking_text').outerHTML)}`);

      this.toggleModal();
      if (response.statusText === 'OK') {
        this.$swal('Yeey!', 'Te-am notat! Vei primii un email in cel mai scurt timp cu '
            + 'datele rezervarii! Te rugăm să verifici si folderul "Spam"', 'success')
          .then(() => {
            window.location.reload();
          });
      } else {
        this.$swal('Oops', 'Ne cerem scuze, a aparut o eroare. '
            + 'Va rugam reincercati mai tarziu!', 'error').then(() => {
          window.location.reload();
        });
      }

      return false;
    },

    hideError() {
      if (this.name.length > 0 && this.email.length > 0 && this.phone.length > 0
          && this.message.length > 0 && this.pers.length > 0 && this.validEmail()) this.error = '';
    },

    validEmail() {
      // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },

    calculatePrice() {
      // this.price = headPrice * this.pers * (this.durationMinutes/15);
      if ((this.durationMinutes % 30) === 0) this.price = (this.durationMinutes / 30) * 25;
      else if (this.durationMinutes > 15) {
        // eslint-disable-next-line radix
        const val = parseInt((this.durationMinutes / 30)) * 25;
        this.price = val + 15;
      } else this.price = (this.durationMinutes / 15) * 15;

      this.price *= this.pers;
    },
  },
};
</script>

<style scoped lang="scss">
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    border-radius: 10px;
    width: 70%;
    margin: 0px auto;
    background-color: #21282e;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Raleway,Lato,sans-serif;
  }

  /* For Mobile */
  @media screen and (max-width: 540px) {
    .modal-container {
      width: 90%;
      border-radius: 10px;
      margin: 0px auto;
      background-color: #21282e;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      font-family: Raleway,Lato,sans-serif;
    }

    .modal-header-text {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;

      font-family: Raleway,Lato,sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  /* For Tablets */
  @media screen and (min-width: 540px) and (max-width: 780px) {
    .modal-container {
      border-radius: 10px;
      width: 90%;
      margin: 0px auto;
      background-color: #21282e;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      font-family: Raleway,Lato,sans-serif;
    }

    .modal-header-text{
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;

      font-family: Raleway,Lato,sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  .modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #000;
    line-height: 40px;
    height: 40px;
    margin-top: 0;
    background: #FFDC00!important;
    padding-left: 10px;
    padding-right: 10px;
  }

  .price {
    margin-top: 20px;
    background: #FFDC00!important;
    color: #000;
    float: right;
    min-height: 30px;
    line-height: 30px;
    padding: 1px;
    text-align: center;
  }

  .modal-body {
    margin: 20px 0;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }

  .modal-default-button {
    float: right;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .close-button {
    cursor: pointer;
    float:right;
  }

  .shadow-box {
    box-shadow: 0 1px 4px 2px rgba(0, 0, 0, .3), 0 0 2px 1px rgba(0, 0, 0, .3) !important;
  }

  .form_input {
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px;
    height: 50px;
    background-color: transparent;
    border: none;
    transition: all .3s ease-in-out;
    border-bottom: 2px solid #949494;
    padding-left: 10px;
    color: white;
    &:focus {
       border-bottom: 2px solid #fff;
       outline: 0;
     }
  }

  .price {
    margin-top: 20px;
    background: #ffdc00!important;
    color: #000;
    float: right;
    min-height: 30px;
    line-height: 30px;
    padding: 1px;
    text-align: center;
  }

  .form_submit {
    color: white;
    border-radius: 0;
    border: none;
    transition: all .3s ease-in-out;
    background-color: #1d2328;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .6), 0 0 2px 1px rgba(0, 0, 0, .45);
  }
</style>
